import React from "react"

import useStoryblok from "../lib/storyblok"

import GlobalLayout from "../components/layout/GlobalLayout"
import { RenderGlobalComponents } from "../globalComponents/GlobalDynamicComponent"
import NoIndexUnlessProduction from "../components/internal/noIndexUnlessProduction/NoIndexUnlessProduction"


function TemplatePage({pageContext, location}:PageTemplateProps):JSX.Element {
  let story:any = pageContext ? pageContext.story : undefined
  story = useStoryblok(story, location)

  let templateHeaderComponents:Array<JSX.Element> = []
  let templateFooterComponents:Array<JSX.Element> = []

  if(story) {
    let templateContent = story.content
    if(templateContent.header_items) {
      templateHeaderComponents = RenderGlobalComponents(templateContent.header_items, false, location?.pathname)
    }
    if(templateContent.footer_items) {
      templateFooterComponents = RenderGlobalComponents(templateContent.footer_items, false, location?.pathname)
    }
  }

  return (
    <GlobalLayout>
      <NoIndexUnlessProduction />
      {templateHeaderComponents}
      <div style={{position: "absolute", bottom: 0, left: 0, width: "100%"}}>
        {templateFooterComponents}
      </div>
    </GlobalLayout>
  )
}

export default TemplatePage